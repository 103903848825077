// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
import { mutations } from './mutations';

export const actions = {
	getMedicalOrgs(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('MedicalOrgs/GetMedicalOrgs', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	setOpenMO(item = null) {
		mutations.setOpenMO(item);
		console.log(item);
	}
};

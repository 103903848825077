import { state } from './state';

export const mutations = {
	testMutations() {
		state.arr = [1, 2, 3, 4, 5];
	},
	setOpenMO(item) {
		state.openMO = item;
	}
};

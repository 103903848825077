<template>
	<span v-if="$route.path === '/osmember/assignMO' || $route.path === '/osmember/archive'">
		<Main :archive="archive" :selectedYear="selectedYear"></Main>
	</span>
	<router-view v-else />
</template>

<script>
import Main from './ui/Main.vue';

export default {
	name: 'AssignMO',
	props: {
		archive: {
			type: Boolean,
			default: () => false
		},
		selectedYear: {
			type: Number,
			default: () => 2022
		}
	},
	components: {
		Main
	}
};
</script>
